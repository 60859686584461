<template>
	<div>
		<div class="ft20 cl-black cl-main ftw600">长者档案</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="姓名">
						<a-input v-model="search.name" placeholder="请输入姓名"></a-input>
					</a-form-item>

					<a-form-item label="手机号">
						<a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
					</a-form-item>

          <a-form-item label="失能">
            <a-select style="width: 115px" placeholder="全部" v-model="search.disability">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option :value="item.value" :key="index" v-for="(item,index) in disabilitys">{{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-item>

					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20 pd20 bg-w">
      <div>
        <a-button style="margin-right: 10px;" icon="plus" type="primary" @click="addElderAct()">添加长者</a-button>
        <a-button type="primary" ghost @click="contactUs()"><i class="iconfont iconimport mr5"></i>导入长者</a-button>
        <a-button type="primary" ghost @click="iportElder2()" class="ml10"><i class="iconfont iconimport mr5"></i>导入长者(简版)</a-button>
      </div>
			<div class="wxb-table-gray mt20">
				<a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading">
					<div class="flex alcenter center" slot="vip" slot-scope="record,index">
						<img v-if="record.cover_img!=null" :src="record.cover_img" class="member-index-face" />
						<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
						<div class="ml5" style="text-align: left;">
							<div>{{record.name}}</div>
							<div class="mt2">{{record.mobile}}</div>
						</div>
					</div>

          <div class="flex alcenter center" slot="gender" slot-scope="gender,record">
            {{gender==1 ? '女' : '男'}}
          </div>

					<template slot="action" slot-scope="record,index">
						<div class="flex center">
							<a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
								<a-menu slot="overlay">
                  <a-menu-item>
                    <a class="menu-act" href="javascript:;" @click="detail(record)">
                      <span>长者详情</span>
                    </a>
                  </a-menu-item>

                  <a-menu-item>
                    <a class="menu-act" href="javascript:;" @click="access(record)">
                      <span>随访记录</span>
                    </a>
                  </a-menu-item>

                  <a-menu-item>
                    <a class="menu-act" href="javascript:;" @click="edit(record)">
                      <span>修改信息</span>
                    </a>
                  </a-menu-item>

                  <a-menu-item>
                    <a class="menu-act" href="javascript:;" @click="viewCode(record)">
                      <span>查看长者码</span>
                    </a>
                  </a-menu-item>

								</a-menu>
							</a-dropdown>
						</div>
					</template>
				</a-table>
			</div>
		</div>

    <div v-if="importFromLoading">
      <import-from :visible="importFromLoading" @cancel="cancelFrom"></import-from>
    </div>

    <div v-if="importFromLoadingElder">
      <import-from2 :visible="importFromLoadingElder" @cancel="cancelFromElder"></import-from2>
    </div>

    <div v-if="vueQrCodeLoading">
      <a-modal v-model="vueQrCodeLoading" title="长者码" @cancel="cancelQrcode" :mask="true" width="250px" :footer="null">
        <vueQrCode :size=qrCodeSize :text="qrCodeText" :visible="vueQrCodeLoading" @cancel="cancelQrcode"> </vueQrCode>
      </a-modal>
    </div>

	</div>
</template>

<script>
  import {listMixin} from '../../common/mixin/list.js';
  import importFrom from '../member/components/elder/modal/import.vue';
  import importFrom2 from '../member/components/elder/modal/import2.vue';
  import vueQrCode from "../../components/code/qrcode";
  import WaterMark from '@/common/watermark.js'
	export default {
    mixins: [listMixin],
    components:{
      importFrom,
      importFrom2,
      vueQrCode,
    },
		data() {
			return {
        waterMark: null,
        importFromLoading:false,
        importFromLoadingElder:false,
        vueQrCodeLoading:false,
				loading: false,
				editloading:false,
				confirmLoading:false,
        currentPage:Number(sessionStorage.getItem('currentPage')) || 1,
				pagination: {
					current: Number(sessionStorage.getItem('currentPage')) || 1, // 默认当前页数
					pageSize: 10, //每页中显示10条数据
					total: 0,
          onChange: (current) => {
            this.currentPage = current;
          }
				},
        disabilitys: [],
				search: {
					name: '',
					mobile: "",
          disability: 0,
					birthday:1
				},
				level:[],
				columns: [
					{title: '编号',dataIndex: 'id',align: 'center'},
					{title: '长者姓名',key: 'vip',align: 'center',scopedSlots: {customRender: 'vip'}},
          {title: '性别',dataIndex: 'gender',align: 'center',scopedSlots: {customRender: 'gender'}},
					{title: '年龄',dataIndex: 'ages',align: 'center',ellipsis: true},
					{title: '出生日期',dataIndex: 'birthday',align: 'center',ellipsis: true},
					{title: '建档时间',dataIndex: 'add_time',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
				member:{},
				member_id:0,

        qrCodeText:"{\"itemId\":1,\"id_number\":\"421083199202044958\",\"employee_name\":\"王五\"}",
        qrCodeSize:200,
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getElderList',{
          name:this.search.name,
					mobile:this.search.mobile,
          disability: this.search.disability,
          birthday:this.search.birthday,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
          this.pagination.showTotal=(total)=>"共"+res.total+"条";
					this.datas=res.list;
          this.disabilitys = res.disabilitys;
					this.level=res.level;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			edit(record){
        sessionStorage.setItem('currentPage',this.currentPage);
        this.$router.push('/member/editelder?id='+record.id);
			},

      detail(record){
        sessionStorage.setItem('currentPage',this.currentPage);
        this.$router.push('/member/elderDetail?id='+record.id);
      },
      access(record){
        sessionStorage.setItem('currentPage',this.currentPage);
        this.$router.push('/member/access?elder_id='+record.id);
      },
      cancelQrcode(){
        this.vueQrCodeLoading = false;
      },
      viewCode(record){
        if(record.code === null){
          this.loading=true;
          this.$http.api('admin/getCode',{
            id:record.id,
            ret_type:'ret_code',
          }).then(res=>{
            this.loading=false;
            this.vueQrCodeLoading = true;
            this.qrCodeText = record.id+'_'+res.code;
          }).catch(res=>{
            this.loading=false;
            this.vueQrCodeLoading=false;
          })
        }else{
          this.vueQrCodeLoading = true;
          this.qrCodeText = record.id+'_'+record.code;
        }

      },

      addElderAct(){
        this.$router.push('/member/editElder');
      },
      contactUs(){
        this.importFromLoading=true;
      },
      iportElder2(){
        this.importFromLoadingElder=true;
      },
      cancelFrom(){
        this.importFromLoading=false;
      },
      cancelFromElder(){
        this.importFromLoadingElder=false;
      },
      handleTableChange(pagination, filters, sorter) {
        this.pagination.current = pagination.current;
        sessionStorage.setItem('currentPage',pagination.current);
        this.getLists();
      },
      handleCancel(){
        this.visible=false;
      },

      // 设置水印
      showMark() {
        let userinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.waterMark = new WaterMark({
          watermark_txt: userinfo.name, // 水印的内容
          watermark_x: 150, // 水印起始位置x轴坐标
          watermark_y: 150, // 水印起始位置Y轴坐标
          watermark_rows: 0, // 水印行数
          watermark_cols: 0, // 水印列数
          watermark_x_space: 100, // 水印x轴间隔
          watermark_y_space: 100, // 水印y轴间隔
          watermark_width: 150, // 水印宽度
          watermark_height: 80, // 水印长度
          watermark_angle: 315, // 水印倾斜度数
          monitor: false,
        });
      },
      // 隐藏水印
      hidden() {
        if (this.waterMark) {
          this.waterMark.remove()
        }
      }

		},

    mounted() {
      this.showMark();
      // 监听离开隐藏水印
      window.addEventListener("beforeunload", this.hidden);
    },
    // 销毁组件
    destroyed() {
      this.hidden()
    }

	}
</script>

<style>
	.member-index-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}

	.member-index-action {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		text-align: center;
		line-height: 32px;
	}
</style>
