<template>
	<div>
		<a-modal :title="getTitle" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
			</template>
			<a-spin :spinning="loading">
        <a-tooltip>
          请严格按照表格格式完善数据<a-button @click="onLink" type="link">下载简版模板</a-button><br/>
          单次导入表格数据不能超过500条<br/>
          <strong>简版不做数据校验，可能引起前端部分功能丢失，请慎重操作</strong>
        </a-tooltip>
				<div>
          <a-divider orientation="left"></a-divider>
					<div class="edit-level-form">
						<div class="edit-level-form-name">
              <a-upload
                  name="file"
                  :showUploadList="false"
                  :customRequest="upload"
                  :beforeUpload="beforeUpload"
              >
                <a-button> <a-icon type="upload" />点击上传</a-button>
              </a-upload>

              <div>
                <a-card :bordered="false">
                  <p v-for="(item,index) in msgArr">{{ item }}</p>
                </a-card>
              </div>

						</div>
					</div>

				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {uploadfiles} from "../../../../../common/request/request";

  export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data() {
			return {
        loading:false,
        exceldemo:'asdfadfdf',
        msgArr:[]
			}
		},
		computed:{
			getTitle(){
        return "导入简单长者信息";
			},
		},
		methods: {
      onLink(){
          window.location.href=process.env.VUE_APP_BASE_API + "upload/demoexcel/ederdemo2.xlsx";
      },
      upload(e) {
        let file = e.file;
        this.loading = true;
        this.$http.uploadfiles(file,'admin/importElder2').then(res=>{
          this.loading = false;
          if(res.code == 200){
            this.$message.success(res.msg);
            this.$emit("cancel");
            this.refreshPage('/member/elder');
          }else{
            this.msgArr = res.data;
          }
        }).catch(res=>{
          this.loading = false;
        });
      },

      beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('最大为2M');
          return false;
        }
        return isLt2M;
      },

			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			checkIt(index){
				this.form.vip_level=this.colors[index].id;
			},


		}
	}
</script>

<style>
	.edit-level-form-name .ant-input{
		height: 40px;
	}

	.edit-level-form-name .ant-input-affix-wrapper .ant-input-prefix {
	    position: absolute;
	    top: 50%;
	    z-index: 2;
	    display: flex;
	    align-items: center;
	    color: rgba(0, 0, 0, 0.65);
	    line-height: 0;
	    transform: translateY(-50%);
	    width: 50px;
		height: 40px;
	}

	.edit-level-form-name .ant-input-affix-wrapper .ant-input:not(:first-child) {
	    padding-left: 74px;
	}

	.edit-level-form-discount .ant-input-number {
		font-size: 12px;
		line-height: 40px;
		font-weight: 400;
		color: #A6AEC2;
		width: 480px;
		height: 40px;
		border-radius: 4px;

	}


	.edit-level-form-discount .ant-input-number-input {
		width: 480px;
		height: 40px;
		padding: 0 80px;
		border-radius: 4px;
	}

	.edit-level-form-discount .ant-input-number-handler-wrap {
		display: none;
	}

	.edit-level-form-discount .input-number-addon{
		height: 40px;
		padding-left: 16px;
		position: absolute;
		top: 0px;
		left: 0;
		text-align: left;
		line-height: 40px;

		font-size: 12px;
		font-weight: 400;
		color: #232529;
	}

	.edit-level-form-discount .input-number-footer{
		height: 40px;
		padding-right: 36px;
		position: absolute;
		top: 0px;
		right: 0;
		line-height: 40px;

		font-size: 12px;
		font-weight: 400;
		color: #656A75;
	}

	.edit-level-form-color-item{
		width: 20px;
		height: 20px;
		border-radius: 4px;
		margin-right: 10px;
		line-height: 24px;
		text-align: center;
	}

	.edit-level-form-color-item .active{
		box-shadow: 0px 2px 4px 0px rgba(220, 173, 133, 0.3);
	}

	.edit-level-form-or{
		width: 30px;
		height: 30px;
		background: rgba(71, 114, 255, 0.06);
		border-radius: 15px;

		font-size: 14px;
		font-weight: 500;
		color: #4772FF;
		line-height: 30px;
		text-align: center;
	}
</style>
